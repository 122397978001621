(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ChatController', ChatController);

    ChatController.$inject = ['$timeout', '$uibModal', '$state', '$stateParams', '$window', 'Chat', 'ChatMessage', 'Profile', 'FirebaseToken', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'ProfileIdByPhone'];

    function ChatController($timeout, $uibModal, $state, $stateParams, $window, Chat, ChatMessage, Profile, FirebaseToken, ParseLinks, AlertService, paginationConstants, pagingParams, ProfileIdByPhone) {

        var vm = this;

        vm.chats = [];
        vm.selectedChat = null;
        vm.chatMessages = [];
        vm.newMessage = '';
        vm.getRepliedMessageContent = getRepliedMessageContent;
        vm.getRepliedMessageImage = getRepliedMessageImage;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 10;
        vm.page = 0;
        vm.pageChatMessage = 0;
        vm.lastPage = false;
        vm.lastMessagesPage = false;
        vm.loading = false;
        vm.isMessagesLoading = false;
        vm.showModal = false;
        vm.modalImageUrl = "";
        vm.unsolvedOnly = null;
        vm.unrepliedOnly = null;
        vm.customerPhone = null;
        vm.showCustomerModal = false;
        vm.customers = [];
        vm.profileLanguage = null;
        vm.messageId = null;
        vm.isEditing = false;
        vm.messageForEdit = null;
        vm.textAreaHeight = 0;

        vm.loadMore = loadMore;
        vm.loadByChatQuery = loadByChatQuery;
        vm.clear = clear;
        vm.selectChat = selectChat;
        vm.sendMessage = sendMessage;
        vm.openProfile = openProfile;
        vm.toggleOptions = toggleOptions;
        vm.toggleChatOptions = toggleChatOptions;
        vm.openImage = openImage;
        vm.closeImage = closeImage;
        vm.applyFilters = applyFilters;
        vm.openCustomerModal = openCustomerModal;
        vm.startNewChat = startNewChat;
        vm.deleteMessage = deleteMessage;
        vm.editMessage = editMessage;
        vm.cancelEditing = cancelEditing;
        vm.saveMessage = saveMessage;
        vm.saveAsReplied = saveAsReplied;

        vm.searchQuery = {
            chatId: pagingParams.chatId,
            field: pagingParams.field,
            solved: pagingParams.solved,
            replied: pagingParams.replied,
        };

        function loadByChatQuery() {
            vm.page = 0;
            vm.lastPage = false;
            vm.loading = false;
            vm.chats = [];
            Chat.query({
                chatId: vm.searchQuery.chatId,
                field: vm.searchQuery.field,
                solved: vm.searchQuery.solved,
                replied: vm.searchQuery.replied,
                page: vm.page,
                size: vm.itemsPerPage,
                sort: sort()
            }, function (response) {
                vm.chats = response;
                vm.chats.forEach(function (chat) {
                    chat.avatar = 'content/images/avatar_profile.png'
                })

                $timeout(function () {
                    var chatList = document.querySelector('.chat-list-container');
                    if (chatList && chatList.scrollHeight === chatList.clientHeight) {
                        vm.loadMore();
                    }
                }, 0);
            }, function (error) {
                AlertService.error('Error loading messages');
            });
            vm.page++;
        }

        function loadMore() {
            if (vm.lastPage || vm.loading) {
                return null;
            } else {
                vm.loading = true;
                loadNext();
            }
        }

        function loadNext() {
            Chat.query({
                chatId: vm.searchQuery.chatId,
                field: vm.searchQuery.field,
                solved: vm.searchQuery.solved,
                replied: vm.searchQuery.replied,
                page: vm.page,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError)
        }

        function sort() {
            const result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page++;
            const result = data;

            if (result == null || result.length < 10) {
                vm.lastPage = true;
            }
            angular.forEach(result, function (chat) {
                vm.chats.push(chat);
            });
            vm.loading = false;
            vm.chats.forEach(function (chat) {
                chat.avatar = 'content/images/avatar_profile.png'
            })

            $timeout(function () {
                var chatList = document.querySelector('.chat-list-container');
                if (chatList && chatList.scrollHeight === chatList.clientHeight) {
                    vm.loadMore();
                }
            }, 0);
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function getRepliedMessageContent(replyMessageId){
            var repliedMessage = null;
            angular.forEach(vm.chatMessages, function (message){
                if (message.id === replyMessageId) {
                    repliedMessage = message;
                }
            })
            return repliedMessage ? repliedMessage.content : '[Message not found]';
        }

        function getRepliedMessageImage(replyMessageId){
            var repliedMessage = null;
            angular.forEach(vm.chatMessages, function (message){
                if (message.id === replyMessageId) {
                    repliedMessage = message;
                }
            })
            return repliedMessage ? repliedMessage.imageUrl : null;
        }

        if ($stateParams.chatId) {
            Chat.get({ id: $stateParams.chatId }, function(chat) {
                vm.selectedChat = chat;
                vm.selectedChat.avatar = 'content/images/avatar_profile.png';
                selectChat(chat);
            });
        }

        function selectChat(chat) {
            vm.isMessagesLoading = true;
            cancelEditing()
            vm.chatMessages = [];
            getCustomerLanguage(chat.customerId);
            vm.selectedChat = chat;
            vm.searchQuery.chatId = chat.id
            ChatMessage.getByChatId({
                chatId: vm.searchQuery.chatId,
                page: vm.pageChatMessage,
                size: vm.itemsPerPage,
                sort: pagingParams.sortChatMessage
            }, function (response) {
                vm.chatMessages = response;
                $state.go('chat', {chatId: chat.id}, {notify: false});
                setTimeout(function () {
                    enableClipboard();
                }, 0);
                vm.isMessagesLoading = false;
                scrollToBottom();
            }, function (error) {
                vm.isMessagesLoading = false;
                AlertService.error('Error loading messages');
            });
        }

        function toggleOptions(message, $event) {
            vm.messageId = message.id;
            vm.chatMessages.forEach(function (msg) {
                if (msg !== message) {
                    msg.showOptions = false;
                }
            });
            message.showOptions = !message.showOptions;
            $event.stopPropagation();
        }

        vm.closeOptions = function () {
            vm.messageId = null;
        }

        vm.saveAsUnsolved = function (message) {
            message.solved = !message.solved;
            vm.messageId = null;
            ChatMessage.update(message, onSaveSuccess, onSaveError);
        }

        function deleteMessage(message) {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/entities/chat-message/chat-message-delete-dialog.html',
                controller: 'ChatMessageDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: message
                }
            });
            modalInstance.result.then(function (deletedMessageId) {
                if (deletedMessageId) {
                    var newMessages = [];
                    angular.forEach(vm.chatMessages, function (msg) {
                        if (msg.id !== deletedMessageId) {
                            newMessages.push(msg);
                        }
                    });
                    vm.chatMessages = newMessages;
                }
            });
        }

        function editMessage(message) {
            vm.isEditing = true;
            vm.newMessage = message.content;
            vm.messageForEdit = message;
            var lines = vm.newMessage.split("\n");
            if (lines.length > 3) {
                vm.textAreaHeight = lines.length * 23;
            } else {
                vm.textAreaHeight = lines.length * 28;
            }

            $timeout(function() {
                angular.element(document.querySelector(".edit-text-area")).focus();
            }, 100);
        }

        function cancelEditing() {
            vm.isEditing = false;
            vm.newMessage = '';
            vm.messageForEdit = null;
        }

        function saveMessage() {
            if (!vm.selectedChat || !vm.newMessage.trim()) return;
            var message = {
                id: vm.messageForEdit.id,
                content: vm.newMessage,
                readStatus: vm.messageForEdit.readStatus,
                fromCustomer: vm.messageForEdit.fromCustomer,
                solved: vm.messageForEdit.solved,
                deliveredStatus: vm.messageForEdit.deliveredStatus,
                chatId: vm.messageForEdit.chatId,
            };

            ChatMessage.update(message, onSuccess, onError);

            function onSuccess(response) {
                vm.newMessage = '';
                vm.isEditing = false;
                angular.forEach(vm.chatMessages, function (message) {
                    if(message.id === vm.messageForEdit.id){
                        message.content = response.content;
                    }
                });
            }

            function onError() {
                vm.newMessage = '';
                vm.isEditing = false;
                window.alert("Error sending message");
            }
        }

        function onSaveSuccess (message) {
            angular.forEach(vm.chats, function (chat) {
                if (chat.id === message.chatId) {
                    chat.solved = message.solved;
                }
            });
            loadByChatQuery();
        }

        function onSaveError () {
            AlertService.error('Error loading messages');
        }

        function sendMessage() {
            if (!vm.selectedChat || !vm.newMessage.trim()) return;

            var message = {
                content: vm.newMessage,
                readStatus: false,
                fromCustomer: false,
                solved: true,
                deliveredStatus: 'PENDING',
                chatId: vm.selectedChat.id,
            };

            ChatMessage.save(message, function(response) {
                vm.chatMessages.push(response);
                vm.newMessage = '';
                scrollToBottom();
            }, function(error) {
                AlertService.error('Error sending message');
            });
        }

        function scrollToBottom() {
            $timeout(function () {
                const chatContainer = angular.element(document.querySelector(".chat-messages"));
                if (angular.isDefined(chatContainer)) {
                    chatContainer[0].scrollTo({
                        top: chatContainer[0].scrollHeight,
                        behavior: "smooth"
                    });
                }
            },0);
        }

        function loadPage() {
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                chatId: vm.searchQuery.chatId,
                field: vm.searchQuery.field,
                solved: vm.searchQuery.solved,
                replied: vm.searchQuery.replied,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function clear() {
            vm.links = null;
            vm.predicate = 'id';
            vm.searchQuery = {
                field: null,
                solved: null,
            };
            vm.transition();
            loadByChatQuery();
        }

        function openProfile(phoneNumber) {
            ProfileIdByPhone.get({phoneNumber: phoneNumber}, onSuccess);

            function onSuccess(data) {
                $state.go('profile-detail', {id: data.profileId});
            }
        }

        function openImage(imageUrl) {
            vm.modalImageUrl = imageUrl;
            vm.showModal = true;
        }

        function closeImage() {
            vm.showModal = false;
            vm.modalImageUrl = "";
        }

        var searchTimeout;

        function applyFilters() {
            if (vm.unsolvedOnly) {
                vm.searchQuery.solved = false;
            } else {
                vm.searchQuery.solved = null;
            }
            if (vm.unrepliedOnly) {
                vm.searchQuery.replied = false;
            } else {
                vm.searchQuery.replied = null;
            }
            if (searchTimeout) {
                $timeout.cancel(searchTimeout);
            }
            searchTimeout = $timeout(function () {
                vm.loadByChatQuery();
            }, 1500);
        }

        function openCustomerModal() {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/entities/chat/chat-customers-dialog.html',
                controller: 'ChatCustomersModalController',
                controllerAs: 'vm',
                backdrop: 'static',
            });

            modalInstance.result.then(function (selectedCustomer) {
                getCustomerLanguage(selectedCustomer.id);
                vm.startNewChat(selectedCustomer);
            });
        }

        function getCustomerLanguage(customerId) {
            FirebaseToken.getOneByCustomer({customerId: customerId}, onSuccess, onError);

            function onSuccess(data) {
                vm.profileLanguage = data.language;
            }

            function onError(data) {
                vm.profileLanguage = null;
            }
        }

        function startNewChat(customer) {
            const newChat = {
                customerPhone: customer.customerPhone,
                solved: true,
                replied: true,
            }
            Chat.save((newChat), function (response) {
                selectChat(response);
                vm.selectedChat.customerPhone = customer.customerPhone
                vm.selectedChat.avatar = 'content/images/avatar_profile.png';
                $state.go('chat', { chatId: response.id }, { notify: false });
                var exists = false;
                angular.forEach(vm.chats, function (chat) {
                    if(chat.id === response.id) exists = true;
                })
                if(!exists){
                    vm.chats.unshift(response);
                }
            }, function () {
                AlertService.error("Error creating new chat");
            });
        }

        function toggleChatOptions($event) {
            vm.selectedChat.showOptions = !vm.selectedChat.showOptions;
            $event.stopPropagation();
        }

        vm.closeChatOptions = function () {
            vm.selectedChat.showOptions = false
        }

        function saveAsReplied(){
            vm.selectedChat.replied = true;
            Chat.update(vm.selectedChat, onSuccess, onError);

            function onSuccess() {
                loadByChatQuery();
            }

            function onError () {
                AlertService.error('Error loading messages');
            }
        }

        function enableClipboard() {
            var clipboardClass = document.getElementsByClassName('mt-clipboard');
            var clipboard = new Clipboard(clipboardClass);
        }
    }
})();
